<template>
  <v-container grid-list-lg fluid>
    <v-row row wrap>
      <v-col cols="12" sm="12" md="4">
        <v-row >
          <v-col class="pt-0" cols="12" sm="12">
            <v-card outlined>
              <v-toolbar dense flat color="grey lighten-3">
                <v-toolbar-title>
                  Note
                </v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="checkRightStatus(36)"
                  small
                  color="info"
                  dark
                  class="mr-2"
                  @click="updateNote()"
                  fab
                  text
                >
                  <v-icon>save</v-icon>
                </v-btn>
                <div v-if="checkRightStatus(37)">
                  <span class="mr-2">Alert</span>
                  <toggle-button
                    @change="updateAlert"
                    :labels="true"
                    v-if="getVendor"
                    color="red"
                    v-model="getVendor.alert"
                    :value="getVendor.alert"
                    :sync="true"
                  ></toggle-button>
                </div>
              </v-toolbar>
              <v-card-text>
                <v-textarea
                  v-model="note"
                  outlined
                  hide-details
                  label="Vendor Note"
                ></v-textarea>

                <!-- <div v-if="getVendor && getVendor.note">{{ getVendor.note }}</div> -->
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
      <v-col cols="12" sm="12" md="8">
        <Comment :RelationId="getVendor" :TypeId="2" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import vendorService from "../service";
import Comment from "@/modules/Comment/components/List";

export default {
  name: "vendor-dashbaord",
  data() {
    return {
      note: null
    };
  },
  computed: {
    ...mapGetters("vendor", ["getVendor"]),
    ...mapGetters("global", ["currentUser", "checkRightStatus"])
  },
  components: {
    Comment,
  },
  watch: {
    getVendor: {
      immediate: true,
      handler(val) {
        this.note = val.note;
      }
    }
  },
  methods: {
    ...mapActions("vendor", ["setVendor"]),
    updateNote() {
      let data = {
        note: this.note
      };
      this.updateVendor(data);
    },
    updateAlert(val) {
      let data = {
        alert: val.value
      };
      this.updateVendor(data);
    },
    updateVendor(data) {
      return vendorService.update(this.getVendor.id, data).then(() => {
        this.$swal
          .mixin({
            toast: true,
            position: "top-end",
            showConfirmButton: false,
            timer: 3000
          })
          .fire({
            icon: "success",
            title: "Update Successfully"
          });
        this.setVendor(this.getVendor.id);
      });
    }
  }
};
</script>
